.auth {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;

    &-page {
        height: 100vh;
        margin-top: 0;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    button {
        margin-top: 50px;
    }
}
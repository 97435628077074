@import '../../../variables.scss';

.partners-list {
    .swiper {
        padding: 20px;
    }
}

.partner-item {
    display: grid;
    place-items: center;
    border-radius: 50px;

    position: relative;
    z-index: 50150;

    cursor: pointer;

    height: 370px;        
    box-shadow: 1px 1px 30px #c5c4c4;

    transition: transform .3s ease;

    img {
        width: 300px;
        margin: 0 auto;
    }

    &:hover {
        transform: scale(.9);
        z-index: 510501501;
    }

    &__name {
        font-size: 3.1rem;

        margin-bottom: 10px;
    }

    &__link {
        font-size: 1.8rem;
        text-decoration: underline;
    }

    &__info {
        align-self: center;
    }

    &.partners-page {
        width: 500px;
        border-radius: 90px;
        height: 500px;
        position: relative;
        border-bottom-left-radius: 0;
        background: #fff;

        transition: height .5s ease,
                    transform 1.5s ease;

        &:hover {
            transform: scale(1);
        }

        &:nth-child(3n) {
            transition: transform 1.5s ease;

            &.no-feedback {
                transition: transform .5s ease;

                &:hover {
                    transform: scale(1.1);
                }
            }

            &:hover {
                transform: translateX(-100%);
            }
        }

        &.no-feedback {
            transition: transform .5s ease;

            &:hover {
                transform: scale(1.1);
            }
        }
    }

    &__rating {
        position: absolute;
        bottom: 30px;
        left: 30px;
        z-index: 50;

        img {
            width: auto;

            height: 37px;
        }
    }

    &__header {
        opacity: 0;
        display: flex;

        animation: fadeIn .5s ease forwards;
        animation-delay: 1.5s;

        img {
            align-self: flex-start;

            &:last-child {
                max-width: 220px;
            }
        }
    }

    &__info {
        align-self: flex-start;
    }
}

.fade-out {
    animation: fadeOut .5s ease forwards;
}

.fade-in {
    opacity: 0;
    display: none;
    animation: fadeIn .5s ease forwards;
    animation-delay: 1s;
    box-shadow: 1px 1px 30px #c5c4c4;
    border-radius: 90px;
    border-bottom-left-radius: 0;
    padding: 50px;
    background: #fff;
    overflow: hidden;

    &.visible {
        display: block;
        opacity: 1;

        animation: grow 1.5s ease forwards;
    }

    position: absolute;
    top: -65px;
    left: 0;
    z-index: 123983412837128937;
    width: 100%;
    height: 100%;
}

.feedbacks {
    opacity: 0;

    animation: fadeIn .5s ease forwards;
    animation-delay: 1.5s;

    h4 {
        font-weight: 600;
        font-size: 2.7rem;
        margin: 40px 0;
    }

    p {
        font-size: 2.7rem;

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes grow {
    from {
        width: 100%;
        height: 100%;
    }

    to {
        width: 200%;
        height: 200%;
    }
}

@media screen and (max-width: 1679px) {
    .partner-item {
        &.partners-page {
            &:nth-child(2n) {
                &.no-feedback {
                    transition: transform .5s ease;
    
                    &:hover {
                        transform: scale(1.1);
                    }
                }
    
                &:hover {
                    transform: translateX(-100%);
                }
            }

            &:nth-child(3n) {
                &.no-feedback {
                    transition: transform .5s ease;
    
                    &:hover {
                        transform: scale(1.1);
                    }
                }
    
                &:hover {
                    transform: none !important;
                }
            }
        }
    }
}

@media screen and (max-width: 1130px) {
    .partner-item {
        &.partners-page {
            &:nth-child(2n) {
                &.no-feedback {
                    transition: transform .5s ease;
    
                    &:hover {
                        transform: scale(1.1);
                    }
                }
    
                &:hover {
                    transform: none !important;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .partner-item {
        &.partners-page {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .fade-in {
        transition: height 1.5 ease;

        &.visible {
            animation: growVertically 1.5s ease forwards;
        }
    }
}

@media screen and (max-width: 768px) {
    .partner-item {
        &.partners-page {
            width: 100%;
        }

        &:not(.partners-page) {
            height: 250px;
        }

        img {
            width: 150px;
        }
    }
}



@media screen and (max-width: 576px) {
    .partner-item {
        &.partners-page {
            width: 100%;
            height: 350px !important;
            margin-right: 0 !important;

            img {
                width: 150px;

                margin: 0;

                &:first-child {
                    margin: 0 auto;
                }
            }
        }

        &__info {
            text-align: left;
        }

        &__header {
            flex-direction: column;
            text-align: center;
        }

        &__info {
            margin: 15px 0;
        }

        &:nth-child(3n) {
            &.no-feedback {
                transition: transform .5s ease;

                &:hover {
                    transform: scale(1.1);
                }
            }

            &:hover {
                transform: none !important;
            }
        }
    }

    .fade-in {
        transition: height 1.5 ease;

        padding: 30px;

        &.visible {
            animation: growVertically 1.5s ease forwards;
        }
    }
}

@keyframes growVertically {
    from {
        width: 100%;
        height: 100%;
    }

    to {
        width: 100%;
        height: 300%;
    }
}
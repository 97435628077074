@import './mixins.scss';

html {
  font-size: 10px;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p, h1,h2,h3,h4,h5,h6 {
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

ul, ol {
  list-style-type: none;
  padding-left: 0;
}

a {
  text-decoration: none;
  color: #fff;
}

.container {
  max-width: 1682px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

button {
  background: transparent;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 10px;
  padding: 0;
  border: none;
  cursor: pointer;
}

.main {
  margin-top: 120px;
  overflow-x: hidden;
  overflow-y: scroll;

  animation: 1s 1 forwards fadeIn ease-in-out;
}

main {
  animation: 1s 1 forwards fadeIn ease-in-out;
}

.text-gradient {
  @include colorTextWithGradient;
}

.pages-title {
  margin: 50px 0 70px;
  font-size: 4.8rem;
  font-weight: 600;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1660px) {
  html {
    font-size: 8px;
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 7px;
  }
}

@media screen and (max-width: 576px) {
  .pages-title {
    margin: 30px 0 45px;
  }

  html {
    font-size: 5.5px;
  }
}


.news-list {
    display: flex;
    flex-wrap: wrap;

    & > div {
        width: 400px;
        height: 400px;

        margin-top: 45px;
        margin-left: 10px;
    }
}

@media screen and (max-width: 576px) {
    .news-list {
        & > div {
            width: 100%;
            height: 300px;
        }
    }
}
.success {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 500000000000;
    background: #fff;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 300px;
    height: 300px;
    box-shadow: 2px 2px 10px #9e9e9e;

    color: green;

    animation: fading 7s linear forwards;
}

.admin-panel {
    button {
        width: 20%;

        margin-top: 50px;

        &:not(:last-child) {
            margin-right: 15px;
        }
    }
}

@keyframes fading {
    from {
        opacity: 0;
    }
    10% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
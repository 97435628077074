.partners-page {
    display: flex;
    flex-wrap: wrap;

    margin-top: -65px;

    & > div {
        margin-top: 65px;

        margin-right: 50px;
    }
}

.partners {
    &__news-list {
        margin-top: 150px;
    }
}
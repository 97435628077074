.input {
    display: flex;
    flex-direction: column;

    margin-top: 30px;

    position: relative;

    span {
        font-size: 2.2rem;
        margin-bottom: 5px;
    }

    input, select, textarea {
        border-radius: 20px;
        background: #fff;
        padding: 30px 20px;
        border: none;
        box-shadow: 2px 2px 10px #9e9e9e;
        font-size: 2.2rem;
    }

    .error {
        color: red;
        font-size: 2rem;
        margin-bottom: 10px;

        span {
            display: block;
        }
    }


    &__dropdown {
        border-radius: 20px;
        background: #fff;
        position: absolute;
        font-size: 2.2rem;
        padding: 30px 20px;
        box-shadow: 2px 2px 10px #9e9e9e;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 150%;
        z-index: 5000;
    }
}

.hider-preview {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(0, -50%);
    width: 10%;
    display: block;
    margin: 0 auto;
}

@media screen and (max-width: 576px) {
    .input {
        input, select, textarea {
            padding: 20px 10px;
        }
    }
}
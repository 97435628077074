.news-item {
    width: 100%;
    height: 420px;

    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;

    cursor: pointer;

    transition: transform .5s ease;

    &__title {
        padding: 0 50px;
        font-size: 4rem;
        color: #fff;
        text-transform: uppercase;
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        border-radius: 50px;
}

    &__hider {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50px;
        z-index: -1;
}

    &:hover {
        transform: scale(.9);
    }
}

@media screen and (max-width: 576px) {
    .news-item {
        height: 300px;

        * {
            font-size: 3rem;
        }
    }
}